import Vue from 'vue'
import VueRouter from 'vue-router'
import pageView from '@/components/pageView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: pageView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
          keepAlive: true,
          isUseCache: false,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Home.vue'),
      },
      {
        path: '/more',
        name: 'more',
        meta: {
          keepAlive: true,
          isUseCache: false,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/More.vue'),
      },
      {
        path: '/detail',
        name: '详情',
        component: () => import('../views/detail.vue'),
      },
      {
        path: '/About',
        name: '关于我们',
        component: () => import('../views/About.vue'),
      },
      {
        path: '/english',
        name: 'english',
        component: () => import('../views/EnglishPage.vue'),
      },
    ],
  },

  // 所有未定义路由，全部重定向到404页
  {
    path: '*',
    redirect: '/404',
    component: pageView,
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('../views/error.vue'),
      },
    ],
  },
]

let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push/replace
//第一个参数：告诉原来的push方法，往哪里跳转（传递哪些参数）
//第二个参数：成功的回调
//第三个参数：失败的回调
//call/apply区别
//相同点：都可以调用函数一次，都可以篡改函数的上下文(this）一次‘
//不同点：call/apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    )
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    )
  }
}

const router = new VueRouter({
  routes,
})

// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
