var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[_c('div',{staticClass:"content"},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.path)?_c('div',{staticClass:"page_footer"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"line_wrap"}),_vm._m(2)]):_c('div',{staticClass:"page_footer"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"line_wrap"}),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact_me_wrap"},[_c('div',{staticClass:"mail_image"},[_c('img',{attrs:{"src":require("../assets/email_icon.png"),"alt":""}})]),_c('div',{staticClass:"mail_info"},[_c('div',[_vm._v("联系我们")]),_c('div',[_c('a',{attrs:{"href":"mailto:contact@eamaster.com"}},[_vm._v("contact@eamaster.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"other_info_wrap"},[_c('div',{staticClass:"logo_image_wrap"},[_c('div',{staticClass:"logo_image"},[_c('img',{attrs:{"src":require("../assets/eamaster_logo_long.png"),"alt":""}})]),_c('div',{staticClass:"logo_image_text"},[_vm._v(" 我们是InternationalACAC、ChinaICAC会员，ChinaICAC白金合作伙伴 ")])]),_c('div',{staticClass:"about_tag"}),_c('div',{staticClass:"qc_image_wrap"},[_c('div',{staticClass:"qc_image_text"},[_vm._v("扫码关注公众号")]),_c('div',{staticClass:"qc_image"},[_c('img',{attrs:{"src":require("../assets/pic_erweima.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"station_flex"},[_c('div',{staticClass:"station_info",staticStyle:{"margin-right":"40px"}},[_vm._v(" Copyright© 2023 杭州课外信息技术有限公司 保留所有权利 ")]),_c('div',{staticClass:"station_info"},[_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602005404"}},[_vm._v(" 浙ICP备15045516号 浙公网安备 33010602005404号 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact_me_wrap"},[_c('div',{staticClass:"mail_image"},[_c('img',{attrs:{"src":require("../assets/email_icon.png"),"alt":""}})]),_c('div',{staticClass:"mail_info"},[_c('div',[_vm._v("Contact us")]),_c('div',[_c('a',{attrs:{"href":"mailto:contact@eamaster.com"}},[_vm._v("contact@eamaster.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"other_info_wrap"},[_c('div',{staticClass:"logo_image_wrap"},[_c('div',{staticClass:"logo_image"},[_c('img',{attrs:{"src":require("../assets/eamaster_logo_long.png"),"alt":""}})]),_c('div',{staticClass:"logo_image_text",staticStyle:{"letter-spacing":"-0.5px"}},[_vm._v(" We are member of the International ACAC and sponsor of the 2016 Conference ")])]),_c('div',{staticClass:"about_tag"}),_c('div',{staticClass:"qc_image_wrap"},[_c('div',{staticClass:"qc_image_text"},[_vm._v("scan to follow us")]),_c('div',{staticClass:"qc_image"},[_c('img',{attrs:{"src":require("../assets/pic_erweima.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"station_flex"},[_c('div',{staticClass:"station_info",staticStyle:{"margin-right":"40px"}},[_vm._v(" Copyright© 2023 Hangzhou Kewai Information Technology Co., Ltd. All rights reserved. ")]),_c('div',{staticClass:"station_info"},[_c('a',{attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602005404"}},[_vm._v(" Zhejiang ICP No. 15045516 Zhejiang Public Network Security No. 33010602005404 ")])])])
}]

export { render, staticRenderFns }