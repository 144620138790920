import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.less'
import './style/reset.css'
import './style/common.less'
import router from './router'
// import 'amfe-flexible/index'

Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
